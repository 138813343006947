<template>
  <v-card max-width="800" class="mx-auto">
    <v-toolbar color="blue" dark>
      <v-toolbar-title
        >Richiesta inviata con successo/Request successfully
        sent</v-toolbar-title
      >
    </v-toolbar>
    <v-alert border="bottom" colored-border type="info" elevation="2">
      Identificativo richiesta/Request Id:
      <strong>{{ this.$route.params.uid }}</strong>
      <div class="my-2" v-if="canGoBackToService()">
        <v-btn text small color="primary" @click="backToService()"
          ><v-icon left>mdi-reply</v-icon> Ritorna al servizio/Back to
          application</v-btn
        >
      </div>
    </v-alert>
  </v-card>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  name: "Feedback",
  methods: {
    backToService: function() {
      let decodedJwt = VueJwtDecode.decode(sessionStorage.getItem("jwt_token"));
      window.location.href = decodedJwt.referer;
    },
    canGoBackToService: function() {
      let decodedJwt = VueJwtDecode.decode(sessionStorage.getItem("jwt_token"));
      if (decodedJwt.referer) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
