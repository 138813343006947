import Vue from "vue";
import VueRouter from "vue-router";
import Support from "../views/Support.vue";
import Feedback from "../views/Feedback";
import ErrorPage from "../views/ErrorPage";
import SessionExpired from "../views/SessionExpired";

Vue.use(VueRouter);

const routes = [
  {
    path: "/support",
    name: "support",
    component: Support
  },
  {
    path: "/feedback/:uid",
    name: "feedback",
    component: Feedback
  },
  {
    path: "/sessionexpired",
    name: "sessionExpired",
    component: SessionExpired
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.query.token != null) {
    if (sessionStorage.getItem("jwt_token") != to.query.token) {
      sessionStorage.clear();
    }
    sessionStorage.setItem("jwt_token", to.query.token);
  }
  next();
});

export default router;
