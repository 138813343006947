<template>
  <v-card max-width="800" class="mx-auto">
    <v-toolbar color="red" dark>
      <v-toolbar-title>Errore/Error</v-toolbar-title>
    </v-toolbar>
    <v-alert border="bottom" colored-border type="error" elevation="2">
      <strong>Attenzione!</strong> Si è verificato un errore. Provare in un
      secondo momento<span v-if="fallbackEmail">
        oppure inviare una mail a :
        <a :href="'mailto:' + fallbackEmail"
          >{{ fallbackEmail }}<v-icon>mail</v-icon></a
        ></span
      ><br />
      <strong>Alert!</strong> An error has occurred. Try it later<span
        v-if="fallbackEmail"
      >
        or send an email to :
        <a :href="'mailto:' + fallbackEmail"
          >{{ fallbackEmail }}<v-icon>mail</v-icon></a
        ></span
      >
      <div class="my-2" v-if="canGoBackToService()">
        <v-btn text small color="primary" @click="backToService()"
          ><v-icon left>mdi-reply</v-icon> Ritorna al servizio/Back to
          application</v-btn
        >
      </div>
    </v-alert>
  </v-card>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  name: "Error",
  data: () => ({
    fallbackEmail: null
  }),
  created() {
    try {
      let decodedJwt = VueJwtDecode.decode(sessionStorage.getItem("jwt_token"));
      if (
        decodedJwt.fallbackAddress != null &&
        decodedJwt.fallbackAddress !== ""
      ) {
        this.$data.fallbackEmail = decodedJwt.fallbackAddress;
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    backToService: function() {
      try {
        let decodedJwt = VueJwtDecode.decode(
          sessionStorage.getItem("jwt_token")
        );
        window.location.href = decodedJwt.referer;
      } catch (e) {
        console.log(e);
      }
    },
    canGoBackToService: function() {
      try {
        let decodedJwt = VueJwtDecode.decode(
          sessionStorage.getItem("jwt_token")
        );
        if (decodedJwt.referer) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
