<template>
  <v-card max-width="800" class="mx-auto">
    <v-toolbar color="orange" dark>
      <v-toolbar-title>Sessione scaduta</v-toolbar-title>
    </v-toolbar>
    <v-alert border="bottom" colored-border type="warning" elevation="2">
      <strong>Attenzione!</strong> La sessione è scaduta.
      <div class="my-2">
        <v-btn text small color="primary" @click="backToService()"
          ><v-icon left>mdi-reply</v-icon> Ritorna al servizio</v-btn
        >
      </div>
    </v-alert>
  </v-card>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  name: "SessionExpired",
  methods: {
    backToService: function() {
      let decodedJwt = VueJwtDecode.decode(sessionStorage.getItem("jwt_token"));
      window.location.href = decodedJwt.referer;
    }
  }
};
</script>

<style scoped></style>
