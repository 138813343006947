<template>
  <span>
    <span loadingTypes v-if="showTypeGroups && loadingRequestTypeGroups">
      <v-skeleton-loader :loading="true" height="94" type="list-item-two-line">
        <v-card>
          <v-card-title>...</v-card-title>
          <v-card-text>...</v-card-text>
        </v-card>
      </v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader :loading="true" height="94" type="list-item-two-line">
        <v-card>
          <v-card-title>...</v-card-title>
          <v-card-text>...</v-card-text>
        </v-card>
      </v-skeleton-loader>
    </span>
    <v-expansion-panels
      v-if="
        showTypeGroups && !loadingRequestTypeGroups && typeGroups.length > 0
      "
    >
      <v-expansion-panel v-for="(group, i) in typeGroups" :key="i">
        <v-expansion-panel-header @click="loadTypes(group.id)"
          ><h2>{{ group.title }}</h2></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-skeleton-loader
            v-if="showTypes && loadingRequestTypes"
            :loading="true"
            height="94"
            type="list-item-two-line"
          >
            <v-card>
              <v-card-title>...</v-card-title>
              <v-card-text>...</v-card-text>
            </v-card>
          </v-skeleton-loader>
          <v-list
            three-line
            v-if="showTypes && !loadingRequestTypes && items.length > 0"
          >
            <template v-for="(item, index) in items">
              <v-subheader
                v-if="item.header"
                :key="item.header"
                v-text="item.header"
              ></v-subheader>
              <v-divider v-if="index > 0" :key="index"></v-divider>
              <v-list-item :key="item.title" @click="loadFields(item.id)">
                <v-list-item-avatar class="no-round" size="60">
                  <v-img
                    :src="'/api/v1/get/icon/' + item.avatarId"
                    contain="true"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.subtitle"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </span>
</template>

<script>
import axios from "axios";
import router from "../router";

export default {
  name: "RequestTypesList",
  data: () => ({
    items: [],
    typeGroups: [],
    loadingRequestTypes: false,
    loadingRequestTypeGroups: false,
    showTypeGroups: true,
    showTypes: true
  }),
  created() {
    //this.loadTypes();
    this.loadTypeGroups();
  },
  methods: {
    loadFields: function(typeId) {
      let typeSelected = null;
      for (let i = 0; i < this.$data.items.length; i++) {
        if (this.$data.items[i].id === typeId) {
          typeSelected = this.$data.items[i];
        }
      }
      this.$emit("setType", typeSelected);
    },
    loadTypeGroups: function() {
      this.$data.loadingRequestTypeGroups = true;
      axios
        .get("/api/v1/get/requestTypeGroups", {
          headers: { "x-auth-token": sessionStorage.getItem("jwt_token") }
        })
        .then(response => {
          this.$data.typeGroups = [];
          for (let i = 0; i < response.data.length; i++) {
            this.$data.typeGroups[this.$data.typeGroups.length] = {
              title: response.data[i].name,
              id: response.data[i].id
            };
          }
          this.$data.loadingRequestTypeGroups = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status);
            if (error.response.status === 410) {
              router.push({ name: "sessionExpired" });
            } else {
              router.push({ name: "error" });
            }
          } else {
            router.push({ name: "error" });
          }
        });
    },
    loadTypes: function(groupId) {
      this.$data.loadingRequestTypes = true;
      axios
        .get("/api/v1/get/requestTypes?groupId=" + groupId, {
          headers: { "x-auth-token": sessionStorage.getItem("jwt_token") }
        })
        .then(response => {
          this.$data.items = [];
          for (let i = 0; i < response.data.length; i++) {
            this.$data.items[this.$data.items.length] = {
              title: response.data[i].name,
              subtitle: response.data[i].description,
              id: response.data[i].id,
              avatarId: response.data[i].icon.id
            };
          }
          console.log(this.$data.items);
          this.$data.loadingRequestTypes = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status);
            if (error.response.status === 410) {
              router.push({ name: "sessionExpired" });
            } else {
              router.push({ name: "error" });
            }
          } else {
            router.push({ name: "error" });
          }
        });
    }
  }
};
</script>

<style scoped>
.no-round {
  border-radius: 0px;
}
</style>
